<template>
  <!-- 新增/编辑 对话框 -->
  <el-dialog
    title="角色信息"
    :visible.sync="dialogVisible"
    width="560px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="onClose"
  >
    <el-form ref="paramsFormRef" label-width="80px" :model="paramsForm" :rules="rules">
      <el-form-item label="角色名" prop="name">
        <el-input v-model.trim="paramsForm.name" placeholder="请输入角色名"></el-input>
      </el-form-item>
      <el-form-item label="唯一编码" prop="code">
        <el-input v-model.trim="paramsForm.code" placeholder="请输入唯一编码"></el-input>
      </el-form-item>
      <el-form-item label="排序" prop="sort">
        <el-input :rows="10" v-model.trim="paramsForm.sort" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input
          type="textarea"
          :rows="4"
          show-word-limit
          :maxlength="200"
          v-model.trim="paramsForm.remark"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onClose">取消</el-button>
      <el-button type="primary" :loading="submitLoading" @click="onSubmit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { postRoleInfo } from '@/api/main/system/roleMana.js';

export default {
  data() {
    return {
      dialogVisible: false,
      submitLoading: false,
      rules: {
        name: [
          { required: true, message: '请输入角色名称', trigger: 'blur' },
          { max: 50, message: '长度在 50 个字符以内', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入唯一编码', trigger: 'blur' },
          { max: 50, message: '长度在 50 个字符以内', trigger: 'blur' }
        ]
      },
      paramsForm: {
        id: '',
        name: '',
        code: '',
        sort: '',
        remark: ''
      }
    };
  },
  methods: {
    open(row) {
      this.dialogVisible = true;
      if (row) {
        this.$nextTick(() => {
          this.paramsForm = Object.assign(this.paramsForm, {
            id: row.id,
            name: row.name,
            code: row.code,
            sort: row.sort,
            remark: row.remark
          });
        });
      }
    },
    onClose() {
      this.dialogVisible = false;
      this.$refs.paramsFormRef.resetFields();
      this.paramsForm.id = '';
    },
    onSubmit() {
      this.$refs.paramsFormRef.validate(valid => {
        if (!valid) return;
        this.submitLoading = true;
        postRoleInfo(this.paramsForm)
          .then(res => {
            const { id } = this.paramsForm;
            this.$message.success(id ? '编辑角色成功！' : '新增角色成功！');
            this.$emit('onSuccess', id);
            this.onClose();
          })
          .finally(() => {
            this.submitLoading = false;
          });
      });
    }
  }
};
</script>
