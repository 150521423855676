<template>
  <div class="equip-container">
    <div class="title">角色管理</div>
    <div class="query-nav">
      <el-form ref="queryFormRef" inline :model="queryForm" label-width="100px">
        <el-form-item prop="name">
          <el-input v-model.trim="queryForm.name" placeholder="角色名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearch">查询</el-button>
          <el-button @click="handleReset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="buts">
      <el-button type="primary" icon="el-icon-plus" @click="handleAdd">新建</el-button>
      <div>
        <el-popconfirm
          title="是否确认批量删除所选角色？"
          cancel-button-type
          @confirm="handleDelete(selectedIdList)"
        >
          <el-button slot="reference" type="danger" :disabled="!selectedIdList.length">批量删除</el-button>
        </el-popconfirm>
      </div>
    </div>
    <div class="table">
      <el-table
        stripe
        size="medium"
        :data="roleList"
        v-loading="tableLoading"
        @selection-change="handleSelectionChange"
      >
        <el-table-column align="center" width="60" type="selection"></el-table-column>
        <el-table-column align="center" width="60" type="index" fixed label="序号"></el-table-column>
        <el-table-column align="center" label="角色名称" prop="name"></el-table-column>
        <el-table-column align="center" label="唯一编码" prop="code"></el-table-column>
        <el-table-column align="center" label="排序" prop="sort"></el-table-column>
        <el-table-column align="center" label="操作" width="240" fixed="right">
          <template slot-scope="{ row }">
            <div class="comm-operation">
              <span class="edit-btn mr-16" @click="$refs.infoDialogRef.open(row)">修改</span>
              <span class="edit-btn mr-16" @click="handleSetPermission(row.id)">权限设置</span>
              <el-popconfirm title="是否确认删除此角色？" cancel-button-type @confirm="handleDelete(row.id)">
                <span slot="reference" class="delete-btn">删除</span>
              </el-popconfirm>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页区域 -->
    <div class="page">
      <el-pagination
        background
        :total="total"
        :current-page="queryForm.pageNo"
        :page-size="queryForm.pageSize"
        :page-sizes="[10, 20, 30, 50]"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="total, prev, pager, next, sizes"
      ></el-pagination>
    </div>
    <!-- 新增/编辑 对话框 -->
    <InfoDialog ref="infoDialogRef" @onSuccess="handleSuccess" />
    <!-- 菜单权限 对话框 -->
    <PermissionDialog ref="permissionDialogRef" />
  </div>
</template>

<script>
import { getRoleList, deleteRoles } from '@/api/main/system/roleMana.js';
import InfoDialog from './infoDialog.vue';
import PermissionDialog from './permissionDialog.vue';

export default {
  name: 'roleMana',
  components: {
    InfoDialog,
    PermissionDialog
  },
  data() {
    return {
      total: 0,
      queryForm: {
        name: '',
        pageNo: 1,
        pageSize: 10
      },
      roleList: [],
      tableLoading: false,
      selectedIdList: []
    };
  },
  created() {
    this.getRoleListData();
  },
  methods: {
    // 获取角色列表
    getRoleListData() {
      this.tableLoading = true;
      getRoleList(this.queryForm)
        .then(res => {
          this.roleList = res.data.rows;
          this.total = res.data.totalRows;
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    // 搜索
    handleSearch() {
      this.queryForm.pageNo = 1;
      this.getRoleListData();
    },
    // 重置
    handleReset() {
      this.queryForm.pageNo = 1;
      this.queryForm.pageSize = 10;
      this.queryForm.name = '';
      this.getRoleListData();
    },
    handleSelectionChange(selection) {
      this.selectedIdList = selection.map(item => item.id);
    },
    // 分页
    handleSizeChange(val) {
      this.queryForm.pageNo = 1;
      this.queryForm.pageSize = val;
      this.getRoleListData();
    },
    handleCurrentChange(val) {
      this.queryForm.pageNo = val;
      this.getRoleListData();
    },
    // 点击新增
    handleAdd() {
      this.$refs.infoDialogRef.open();
    },
    // 新增/编辑成功
    handleSuccess(data) {
      // 编辑成功，原地刷新；新增成功，重置首页
      data ? this.getRoleListData() : this.handleReset();
    },
    // 删除角色
    handleDelete(data) {
      let ids;
      let message = '';

      if (typeof data === 'string') {
        /* 单个删除 */
        ids = data;
        message = '角色删除成功！';
      } else if (Array.isArray(data)) {
        /* 批量删除 */
        ids = data.join(',');
        message = '批量删除成功！';
      }

      this.tableLoading = true;
      deleteRoles({ ids })
        .then(res => {
          this.$message.success(message);
          this.handleReset();
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    // 点击权限设置
    handleSetPermission(id) {
      this.$refs.permissionDialogRef.open(id);
    }
  }
};
</script>

<style lang="less" scoped>
.equip-container {
  .page {
    margin: 16px 0;
    float: right;
  }
}
.query-nav {
  border-bottom: 1px solid #dcdfe7;
  border-top: 1px solid #dcdfe7;
  padding-top: 24px;
}
.buts {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  span {
    font-size: 14px;
    font-weight: 400;
    color: #117bf7;
  }
}
.table {
  margin-top: 12px;
}

.title {
  padding: 0 16px 12px;
  font-size: 16px;
  font-weight: bold;
}
</style>
