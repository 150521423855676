import { axios } from '@/utils/request';

/**
 * 获取角色列表
 */
export function getRoleList(params) {
  return axios({
    url: '/sysRole/page',
    method: 'get',
    params
  });
}
/**
 * 新增/编辑角色
 */
export function postRoleInfo(data) {
  return axios({
    url: '/sysRole/addOrEdit',
    method: 'post',
    data
  });
}
/**
 * 删除角色
 */
export function deleteRoles(data) {
  return axios({
    url: '/sysRole/delete',
    method: 'post',
    data
  });
}
/**
 * 获取角色菜单
 */
export function getRoleMenuList(params) {
  return axios({
    url: '/sysRole/ownMenu',
    method: 'get',
    params
  });
}
/**
 * 获取所有菜单
 */
export function getMenuTreeList(params) {
  return axios({
    url: '/sysMenu/treeForGrant',
    method: 'get',
    params
  });
}
/**
 * 角色授权菜单
 */
export function postGrantMenu(data) {
  return axios({
    url: '/sysRole/grantMenu',
    method: 'post',
    data
  });
}
