<template>
  <el-dialog
    title="授权菜单"
    :visible.sync="dialogVisible"
    width="560px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="onCancel"
  >
    <el-tree
      style="min-height: 500px;max-height:500px;overflow: auto;"
      show-checkbox
      default-expand-all
      v-loading="treeLoading"
      node-key="id"
      ref='tree'
      :props="defaultProps"
      :data="menuTreeList"
      :default-checked-keys="checkedIdList"
      @check="handleCheckChange"
    ></el-tree>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onCancel">取消</el-button>
      <el-button type="primary" :loading="submitLoading" @click="onConfirm">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getMenuTreeList, getRoleMenuList, postGrantMenu } from '@/api/main/system/roleMana.js';

export default {
  data() {
    return {
      dialogVisible: false,
      treeLoading: false,
      submitLoading: false,
      defaultProps: {
        label: 'title',
        children: 'children'
      },
      menuTreeList: [],
      checkedIdList: [],
      paramsForm: {
        id: '',
        grantMenuIdList: []
      }
    };
  },
  mounted() {},
  methods: {
    open(id) {
      this.dialogVisible = true;
      this.treeLoading = true;
      /* 获取最新的所有菜单 */
      getMenuTreeList()
        .then(res => {
          this.menuTreeList = res.data;
          return getRoleMenuList({ id });
        })
        /* 获取该角色所拥有的菜单权限 */
        .then(res => {
          this.checkedIdList = res.data;
          this.paramsForm.id = id;
          this.paramsForm.grantMenuIdList = res.data;
        })
        .finally(() => {
          this.treeLoading = false;
        });
    },
    handleCheckChange(data, checkedNodes) {
      const { checkedKeys } = checkedNodes;
      // const checkedKeys = this.$refs.tree.getCheckedKeys().concat(this.$refs.tree.getHalfCheckedKeys())
      this.paramsForm.grantMenuIdList = checkedKeys;
    },
    // 取消
    onCancel() {
      this.dialogVisible = false;
      this.checkedIdList = [];
    },
    // 确定
    onConfirm() {
      this.submitLoading = true;
      postGrantMenu(this.paramsForm)
        .then(res => {
          this.$message.success('权限分配成功！');
          this.onCancel();
        })
        .finally(() => {
          this.submitLoading = false;
        });
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .el-loading-mask {
  background-color: #fff;
}

.el-tree {
  height: 600px;
  overflow: auto
}
</style>
